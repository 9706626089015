import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M14.0037 12.2737L11.1963 10.9637C10.9513 10.8499 10.75 10.9774 10.75 11.2487V13.7162C10.75 13.9874 10.9513 14.1149 11.1963 14.0012L14.0025 12.6912C14.2488 12.5762 14.2487 12.3887 14.0037 12.2737ZM12 0.482422C5.3725 0.482422 0 5.85492 0 12.4824C0 19.1099 5.3725 24.4824 12 24.4824C18.6275 24.4824 24 19.1099 24 12.4824C24 5.85492 18.6275 0.482422 12 0.482422ZM12 17.3574C5.8575 17.3574 5.75 16.8037 5.75 12.4824C5.75 8.16117 5.8575 7.60742 12 7.60742C18.1425 7.60742 18.25 8.16117 18.25 12.4824C18.25 16.8037 18.1425 17.3574 12 17.3574Z" fill="#E6E6E6"/>
    </Svg>
  );
};

export default Icon;
